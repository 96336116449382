import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReadMoreDialogComponent } from '../read-more-dialog/read-more-dialog.component';
import { MinistryService } from 'src/app/admin/services/ministry.service';

@Component({
  selector: 'app-bedieninge',
  templateUrl: './bedieninge.component.html',
  styleUrls: ['./bedieninge.component.scss']
})
export class BedieningeComponent implements OnInit {
  groups = [
    {
      title: 'Bedieninge',
      description: '',
      url: '',
      icon: '../../../assets/icon_bedien.png',
      video: 'https://www.youtube.com/embed/FLZ7b8dIDbg?si=oPrTNJmebplVVU5U'
    },
    {
      title: 'Belangegroepe',
      description: '',
      url: '',
      icon: '../../../assets/icon_belangegroepe.png',
      video: 'https://www.youtube.com/embed/asFRLKT4BMI?si=BjRsDTnfNNJgA51F'
    },
    {
      title: 'Groeigroepe',
      description: '',
      url: '',
      icon: '../../../assets/icon_groeigroepe.png',
      video: 'https://www.youtube.com/embed/U42oiaxshrE?si=pyFT5IVBaqZtTIYX'
    },
    {
      title: 'Lewensgroei',
      description: '',
      url: '',
      icon: '../../../assets/icon_lewensgroei.png',
      video: 'https://www.youtube.com/embed/g08VO95iuvE?si=9l4Xv1wE2gQ-gYYS'
    }
  ]
  constructor(private ministryService: MinistryService) { }
  ministries;
  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  ngOnInit(): void {
    this.ministryService.ministries$.subscribe(res => {
      if(res)
      this.ministries = this.groupBy(res, 'type');
    })
  }

  readonly dialog = inject(MatDialog);

  openDialog(group: any) {
    if(group.title === 'Bedieninge'){
      group = {...group, items: this.ministries['Bediening']}
    }else if(group.title === 'Belangegroepe'){
      group = {...group, items: this.ministries['Belangegroep']}
    }else if(group.title === 'Lewensgroei'){
      group = {...group, items: this.ministries['Lewensgroei']}
    }else if(group.title === 'Groeigroepe'){
      group = {...group, items: this.ministries['Groeigroep']}
    }else{
      group = {...group, items: this.ministries['undefined']}
    }
    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: group,
      width: '800px',
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
