<div mat-dialog-title class="d-flex justify-content-between text-white p-3 m-0">
    <h1 class="m-0">Kruin Roeping</h1>
    <button mat-button (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
      <mat-dialog-content class="content h-100 p-3 m-0">
        <div class="h-100 w-100 bg-50perc-black p-3">
                <p class="mat-title text-white">
                    By KRUIN weet ons hoe kompleks die lewe is, en daarom wil ons jou help! Ons nooi jou uit om te kom nes jy is, en Jesus te volg. Ons kan saam 'n verskil maak deur GOED te DOEN in ons gemeenskap in Kempton Park.
                </p>
                <img class="img-fluid" src="../../../assets/logo.png"/>
        </div>
    </mat-dialog-content>