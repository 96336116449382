
<div class="main-wrapper">
  <div class="container scrollable">
    <mat-toolbar class="d-flex justify-content-between bg-white mt-3 p-3">
      <div class="desktop">
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="kykMenu"
          #kykMenuTrigger="matMenuTrigger"
        >
          KYK
        </button>
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="leesMenu"
          #leesMenuTrigger="matMenuTrigger"
        >
          LEES
        </button>
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="luisterMenu"
          #luisterMenuTrigger="matMenuTrigger"
        >
          LUISTER
        </button>
        <button
          class="m-1 mat-title"
          mat-button
          (click)="openContactDialog()"
        >
          KONTAK
        </button>
      </div>
      <mat-menu class="mt-3" #kykMenu="matMenu" [overlapTrigger]="false">
        <span
          class="d-flex flex-column"
          (mouseleave)="kykMenuTrigger.closeMenu()"
        >
          <button class="p-3 link" mat-button>
            <a target="_blank" href="https://www.youtube.com/@kruinkerk786">
              Aanlyn Preke</a
            >
          </button>
          <button
            class="p-3 link"
            mat-button
            routerLink="fotos"
            routerLinkActive="active"
          >
            Fotos
          </button>
        </span>
      </mat-menu>
      <mat-menu class="mt-3" #leesMenu="matMenu" [overlapTrigger]="false">
        <span
          class="d-flex flex-column"
          (mouseleave)="leesMenuTrigger.closeMenu()"
        >
          <button
            class="p-3 link"
            mat-button
            (click)="openAboutDialog()"
          >
            Roeping
          </button>
          <button class="p-3 link" mat-button>
            <a
              [href]="nuusbrieflink | safeUrl"
              target="_blank"
              >Nuusbrief</a
            >
          </button>
        </span>
      </mat-menu>
      <mat-menu class="mt-3" #luisterMenu="matMenu" [overlapTrigger]="false">
        <span
          class="d-flex flex-column"
          (mouseleave)="luisterMenuTrigger.closeMenu()"
        >
          <button class="p-3 link" mat-button>
            <a href="https://soundcloud.com/kommunikasie" target="_blank"
              >Potgooi</a
            >
          </button>
        </span>
      </mat-menu>

      <div class="mobile">
        <button mat-stroked-button color="accent" [matMenuTriggerFor]="menu">
          MENU
        </button>
        <mat-menu #menu="matMenu">
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            target="_blank"
            href="https://www.youtube.com/@kruinkerk786"
          >
            Aanlyn Preke
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            routerLink="fotos"
            routerLinkActive="active"
          >
            Fotos
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            (click)="openContactDialog()"
          >
            Kontak
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            (click)="openAboutDialog()"
          >
            Roeping
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            [href]="nuusbrieflink | safeUrl"
            target="_blank"
            >Nuusbrief
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            href="https://soundcloud.com/kommunikasie"
            target="_blank"
            >Potgooi</a
          >
          <a
            class="link d-flex align-items-center text-white"
            mat-raised-button
            color="primary"
            (click)="Login()"
            >Teken in/Registreer</a
          >
        </mat-menu>
      </div>
      <div class="spacer"></div>
      <div class="desktop">
        <a
        class="p-1 pr-3 pl-3 m-0 link d-flex align-items-center text-white mat-title"
        mat-raised-button
        color="primary"
        style="border-radius: 30px;"
        (click)="Login()"
        >Teken in / Registreer</a
      >
      </div>
      <div class="pl-3 pr-1 desktop">
        <img class="logo-md" src="assets/kruin50.png" />
      </div>
      <div class="pl-2 pr-3">
        <img class="logo-md link" routerLink="home" src="assets/logo.png" />
      </div>
    </mat-toolbar>
  </div>
  <div class="p-0 dashboard-container flex-1">
    <router-outlet></router-outlet>
    <div class="container mt-3">
      <div class="row bg-black p-4">
        <div class="col-md-3">
            <div class="section-title">
                <h3 class="m-0 text-grey mat-title">Vinnige skakels</h3>
              </div>

              <div class="row m-0">
                <div class="col-md-12 p-0">
                    <a
                      class="mat-title p-0 link d-flex align-items-center text-white"
                      target="_blank"
                      href="https://www.youtube.com/@kruinkerk786"
                    >
                      Aanlyn Preke
                    </a>
                    <a
                      class="mat-title p-0 link d-flex align-items-center text-white"
                      routerLink="fotos"
                      routerLinkActive="active"
                    >
                      Fotos
                    </a>
                    <a
                      class="mat-title p-0 link d-flex align-items-center text-white"
                      (click)="openAboutDialog()"
                    >
                      Roeping
                    </a>
                    
                      <a
                        class="mat-title p-0 link d-flex align-items-center text-white"
                        [href]="nuusbrieflink"
                        target="_blank"
                        >Nuusbrief
                      </a>
                      <a
                        class="mat-title p-0 link d-flex align-items-center text-white"
                        href="https://soundcloud.com/kommunikasie"
                        target="_blank"
                        >Potgooi</a
                      >
                      <a
                      class="mat-title link d-flex align-items-center text-white"
                      mat-raised-button
                      color="primary"
                      (click)="Login()"
                      >Teken in/Registreer</a
                    >
                    </div>
              </div>
        </div>
        <div class="col-md-4">
          <div class="section-title">
            <h3 class="m-0 text-grey mat-title">Vorms en dokumente</h3>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-0">
          <a
            class="mat-title p-0 mb-3 link d-flex align-items-center text-white"
            (click)="
              getFileByIdForDownload(
                'NuweIntrekkers',
                '1-howhTayk5eDAsOqTxrHmdzSZhRjXLim',
                'Word'
              )
            "
          >
            Nuwe Intrekkers <mat-icon size="sm">download</mat-icon>
          </a>
          <a
            class="mat-title p-0 mb-3 link d-flex align-items-center text-white"
            (click)="
              getFileByIdForDownload(
                'Aansoek om Doop',
                '1HIc_qVA7Vx0-IYxfP0WAao42naFD33zo',
                'Word'
              )
            "
          >
            Aansoek om Doop <mat-icon size="sm">download</mat-icon>
          </a>

          <div
  class="d-flex justify-content-center flex-column align-items-center"
  *ngIf="downloading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
          </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="section-title">
            <h2 class="mat-title m-0 text-grey">Kruin-nuus</h2>
          </div>
          <a
            class="mat-title link text-white"
            color="primary"
            target="_blank"
            mat-raised-button
            [href]="nuusbrieflink | safeUrl"
            >Nuusbrief</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
