import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBook, faCalendar, faChurch, faPlay, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {
  faYoutube = faPlay;
  faSoundcloud = faVolumeUp;
  eredienste = [
    {
      title: 'Koffie Verkope',
      time: '08:30'
    },
    {
      title: 'Oggenddiens',
      time: '9:00'
    },
    {
      title: 'Kinderkerk',
      time: '9:00'
    },
    {
      title: 'Koffie Verkope',
      time: '16:45'
    },
    {
      title: 'Aanddiens',
      time: '17:15'
    },
    {
      title: 'Jeugkerk',
      time: '17:15'
    }
  ]
  gebeure = [];
  faBook = faBook;
  faChurch = faChurch;
  faCalendar = faCalendar;
  votd;
  constructor(private router: Router, private dataService: DataService, private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.dataService.verseOfTheDay$.subscribe(res => {
      this.votd = res;
    })

    // this.appointmentService.appointments$.subscribe(res => {
    //   console.log(res)
    //   this.gebeure = res;
    // })
  }

  viewCalendarGallery(){
    this.router.navigate(['.', 'kalender']);
  }

}
