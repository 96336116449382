import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
interface DialogData {
    title: string,
    description: string,
    url: string,
    icon: string,
    qrCode: string,
    video: string,
    items: any
}
@Component({
  selector: 'app-read-more-dialog',
  templateUrl: './read-more-dialog.component.html',
  styleUrls: ['./read-more-dialog.component.scss']
})
export class ReadMoreDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    console.log(this.data.items)
  }

  readonly dialog = inject(MatDialog);
  openDialog(group: any) {
    const mappedGroupFromItem = {
      title: group.name,
    description: group.notes,
    url: '',
    icon: group.logo.source,
    video: '',
    qrCode: group.qrCode ? group.qrCode.source : null
    }
    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: mappedGroupFromItem,
      width: '800px',
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
