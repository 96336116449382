<div class="d-flex justify-content-between">
  <div class="d-flex">
  <img class="logo" [src]="data.icon ? data.icon : '../../../assets/placeholder_class.png'"/>
  <h2 class="flex-1 mat-title m-0 ml-2">{{data.title}}</h2>
</div>
  <a mat-button [mat-dialog-close]="true" href="https://docs.google.com/forms/d/e/1FAIpQLSeAs12KY-tHqLCXHB1p9BZ777ciizTCRCyAxkXQh-n00smnsw/viewform?usp=sf_link" target="_blank" cdkFocusInitial>Word deel</a>
</div>
<mat-dialog-content class="mat-typography pt-3">
  <iframe *ngIf="data.video" width="100%" height="450" [src]="data.video | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  <div *ngIf="data.qrCode" class="mr-3 d-flex flex-column justify-content-center align-items-center">
    <h3>{{data.title}}</h3>
    <p class="mat-subtitle text-grey">Whatsapp Groep</p>
    <img class="img-fluid" [src]="data.qrCode">
  </div>
  <!-- <p>{{data.description}}</p>
  <ng-container *ngIf="data.items">
    <div class="row">
    <div class="col-md-4 pb-3" *ngFor="let item of data.items">
      <div class="p-2 d-flex w-100 align-items-center mat-stroked-button" (click)="openDialog(item)">
        <div class="initials mr-3"><img [src]="item.logo.source ? item.logo.source : '../../../assets/placeholder_class.png'"></div>
        <h3 class="mat-subtitle flex-1 m-0">{{item.name}}</h3>
      </div>
    </div>
  </div>
  </ng-container> -->

</mat-dialog-content>
