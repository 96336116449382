<div class="container">
<div class="p-3 m-3 relative bg-primary">
    <h1 class="text-white m-0">Kalender</h1>
</div>
<div
  class="d-flex justify-content-center flex-column align-items-center"
  *ngIf="loading"
>
  <div class="mt-5 mb-5">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div *ngIf="projectPhotos && projectPhotos.length > 0" class="row scrollable p-2 m-0 flex-1">
    <lightgallery *ngIf="!loading && projectPhotos" [settings]="settings" [BeforeSlide]="onBeforeSlide" class="row justify-content-center scrollable p-2 flex-1">
        <p *ngIf="projectPhotos.length < 1">No photos</p>
      
        <a *ngFor="let photo of projectPhotos"
          class="col-md-4 d-flex flex-column align-items-center justify-content-center photo-container"
          [href]="photo.baseUrl+'=w'+photo.mediaMetadata.width+'-h'+photo.mediaMetadata.height+'-c'">
          <img class="img-fluid"
          src="{{photo.baseUrl}}" />
        </a>
      
      </lightgallery>
</div>
</div>