<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<form
  *ngIf="!loading"
  class="user mb-5"
  [formGroup]="ministryForm"
  (ngSubmit)="saveMinistry()"
>
  <div class="mb-3 mt-3">
    <div class="row m-0 mb-3">
      <div [ngClass]="this.ministry.id !== '' ? 'col-md-8' : 'col-md-12'" class="d-flex align-items-center">
        <div class="border-primary p-2 mb-3 w-100 h-100">
          
            <div class="photo-container border-radius-5 link" (click)="uploadFile()">
              <fa-icon
                class="upload-icon text-secondary"
                [icon]="faUpload"
                size="2x"
              ></fa-icon>
              <img
                class="text-center margin-auto link"
                [src]="logo"
                #imagePreview
                alt="image"
              />
              <input
                type="file"
                (change)="onFileSelected($event)"
                #fileInput
                formControlName="logo"
                style="display: none"
              />
            </div>

              <mat-form-field class="w-100 mb-3">
                <mat-label>Naam</mat-label>
                <input class="m-0" matInput formControlName="name" placeholder="Naam" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Tipe</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="Groeigroepe">Groeigroep</mat-option>
                  <mat-option value="Lewensgroei">Lewensgroei</mat-option>
                  <mat-option value="Bediening">Bediening</mat-option>
                  <mat-option value="Belangegroep">Belangegroep</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field appearance="fill" class="w-100">
                <mat-label>Meal Plan</mat-label>
                <mat-select formControlName="mealPlan" name="mealPlan">
                  <mat-option
                    *ngFor="let mealPlan of mealPlans"
                    [value]="mealPlan"
                    >{{ mealPlan.title }}</mat-option
                  >
                </mat-select>
              </mat-form-field> -->
              <ng-container formArrayName="subMinistries">
                <h3>Sub Bedieninge</h3>
                <ng-container *ngFor="let subMinistryForm of subMinistry?.controls; let i = index">
                  <div class="flex-wrap medication-form-row d-flex justify-content-between align-items-center" [formGroup]="subMinistryForm">
                    <mat-form-field  class="flex-1 ml-3">
                      <mat-label>Naam</mat-label>
                      <input matInput placeholder="Klas naam" name="name" formControlName="name" />
          
                    </mat-form-field>
                    <mat-form-field  class="flex-1  ml-3">
                      <!-- <mat-label>Qty</mat-label> -->
                      <input matInput name="description" placeholder="Beskrywing" formControlName="description" />
                    </mat-form-field>
                    <mat-form-field  class="flex-wrap ml-3 input-sm">
                      <mat-label>Kode</mat-label>
                      <input matInput placeholder="Klas kode" name="ministryCode" formControlName="ministryCode" />
          
                    </mat-form-field>
                      <fa-icon class="ml-3 mr-3 link" [icon]="faTrash" (click)="removeSubMinistry(i)"></fa-icon> 
                  </div>
              </ng-container>
            </ng-container>
              <button mat-mini-fab type="button" (click)="addSubMinistry()">
                <fa-icon [icon]="faPlus"></fa-icon> 
              </button>
              <h3>Notas</h3>
              <mat-form-field class="w-100" appearance="fill">
                <textarea
                  rows="5"
                  matInput
                  placeholder="Notas"
                  formControlName="notes"
                ></textarea>
              </mat-form-field>
              <h3>QR Kode</h3>
              <div class="qr-container link" (click)="uploadQRCode()">
                <fa-icon
                  class="upload-icon text-secondary"
                  [icon]="faUpload"
                  size="2x"
                ></fa-icon>
                <img
                  class="text-center margin-auto link"
                  [src]="qrCode"
                  #qrPreview
                  alt="image"
                />
                <input
                  type="file"
                  (change)="onQRCodeSelected($event)"
                  #qrInput
                  formControlName="qrCode"
                  style="display: none"
                />
              </div>
        </div>
      </div>
      <div *ngIf="this.ministry.id !== ''" class="col-md-4 d-flex flex-column">
        <h3>Lidmate</h3>
        <div class="d-flex flex-wrap">
          <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let member of members" (click)="openMember(member.ID)"> <fa-icon [icon]="faUser"></fa-icon>  {{member.Name}} {{member.Surname}}</button>
        </div>
        <hr/>
        <h3>Personeel</h3>
        <div class="d-flex flex-wrap">
          <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let t of teachers" (click)="openTeacher(t.id)"> <fa-icon [icon]="faUser"></fa-icon>  {{t.title}} {{t.name}} {{t.surname}}</button>
        </div>
      </div>

    </div>
  </div>
  <mat-toolbar class="d-flex justify-content-between">
    <button
      *ngIf="ministry.id"
      type="button"
      (click)="openDeleteDialog()"
      mat-stroked-button
      color="accent"
    >
      Verwyder
    </button>
    <button type="submit" mat-raised-button color="primary">Stoor</button>
  </mat-toolbar>
</form>
