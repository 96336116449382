import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule, momentAdapterFactory } from '../shared/shared.module';
import { AdminRoutingModule } from './public-site.routing.module';
import { ProjectsComponent } from './projects/projects.component';
import { ViewProjectComponent } from './view-project/view-project.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { BedieningeComponent } from './bedieninge/bedieninge.component';
import { WordDeelComponent } from './word-deel/word-deel.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CalendarComponent } from './calendar/calendar.component';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    PublicComponent,
    HomeComponent,
    ProjectsComponent,
    ViewProjectComponent,
    AboutComponent,
    ContactComponent,
    BedieningeComponent,
    WordDeelComponent,
    CalendarComponent
  ],
  imports: [GoogleMapsModule,CommonModule, FormsModule, ReactiveFormsModule, AdminRoutingModule, FontAwesomeModule, SharedModule, MatTableModule, MatCheckboxModule, MatSidenavModule, MatFormFieldModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule, MatCardModule, MatProgressSpinnerModule, MatTabsModule, MatPaginatorModule, MatInputModule, MatTableModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }), MatDialogModule],
})
export class PublicSiteModule { }
