import { Component, OnInit } from '@angular/core';
import { StudentService } from 'src/app/admin/services/student.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  loading = false;
  projectPhotos = [];
  albumTitle = '';
  albumId = "AKb-cJQOXt4NqXespDXbG14Cte8ylhI0oLkfX3e88M96liwmIrXq7M5h7q49CabLqv09G5CEi86d"
  constructor(private dataService: DataService, private studentService: StudentService,) { }

  async ngOnInit(): Promise<void> {
        await this.studentService.getAlbumById(this.albumId).then(res => {
          this.projectPhotos = res.mediaItems;
          this.albumTitle = 'Kalender';
        });
  }

}
